"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isJoinSuccessMessage = base_message_1.createTypeGuard("join-success");
class JoinSuccessMessage extends base_message_1.BaseMessage {
    constructor(self) {
        super();
        this.self = self;
        this.type = "join-success";
    }
}
exports.JoinSuccessMessage = JoinSuccessMessage;
