"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isMoveCardMessage = base_message_1.createTypeGuard("move-card");
class MoveCardMessage extends base_message_1.BaseMessage {
    constructor(card, x, y) {
        super();
        this.card = card;
        this.x = x;
        this.y = y;
        this.type = "move-card";
    }
}
exports.MoveCardMessage = MoveCardMessage;
