"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_entity_1 = require("./base-entity");
exports.isCard = (possibleCard) => {
    return "type" in possibleCard && possibleCard.type === "card";
};
class Card extends base_entity_1.BaseEntity {
    constructor(value) {
        super();
        this.value = value;
        this.type = "card";
    }
}
exports.Card = Card;
