"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isFinishTurnMessage = base_message_1.createTypeGuard("finish-turn");
class FinishTurnMessage extends base_message_1.BaseMessage {
    constructor(order) {
        super();
        this.order = order;
        this.type = "finish-turn";
    }
}
exports.FinishTurnMessage = FinishTurnMessage;
