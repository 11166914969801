"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isPlayerJoinMessage = base_message_1.createTypeGuard("player-join");
class PlayerJoinMessage extends base_message_1.BaseMessage {
    constructor(currentPlayers) {
        super();
        this.currentPlayers = currentPlayers;
        this.type = "player-join";
    }
}
exports.PlayerJoinMessage = PlayerJoinMessage;
