"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GameStates;
(function (GameStates) {
    GameStates[GameStates["ERROR"] = 0] = "ERROR";
    GameStates[GameStates["WAITING"] = 1] = "WAITING";
    GameStates[GameStates["SETUP"] = 2] = "SETUP";
    GameStates[GameStates["PREGAME"] = 3] = "PREGAME";
    GameStates[GameStates["GAME"] = 4] = "GAME";
    GameStates[GameStates["WON"] = 5] = "WON";
    GameStates[GameStates["LOST"] = 6] = "LOST";
})(GameStates = exports.GameStates || (exports.GameStates = {}));
