"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isNewCardStackMessage = base_message_1.createTypeGuard("new-card-stack");
class NewCardStackMessage extends base_message_1.BaseMessage {
    constructor(stack) {
        super();
        this.stack = stack;
        this.type = "new-card-stack";
    }
}
exports.NewCardStackMessage = NewCardStackMessage;
