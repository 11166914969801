"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isGameLostMessage = base_message_1.createTypeGuard("game-lost");
class GameLostMessage extends base_message_1.BaseMessage {
    constructor(remainingCards, reason) {
        super();
        this.remainingCards = remainingCards;
        this.reason = reason;
        this.type = "game-lost";
    }
}
exports.GameLostMessage = GameLostMessage;
