"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_entity_1 = require("./base-entity");
exports.isCardStack = (possibleCardStack) => {
    return "type" in possibleCardStack && possibleCardStack.type === "card-stack";
};
class CardStack extends base_entity_1.BaseEntity {
    constructor(id, direction, values) {
        super();
        this.id = id;
        this.direction = direction;
        this.values = values;
        this.type = "card-stack";
    }
}
exports.CardStack = CardStack;
