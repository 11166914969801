"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isGameWonMessage = base_message_1.createTypeGuard("game-won");
class GameWonMessage extends base_message_1.BaseMessage {
    constructor() {
        super();
        this.type = "game-won";
    }
}
exports.GameWonMessage = GameWonMessage;
