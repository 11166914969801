"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isStartGameMessage = base_message_1.createTypeGuard("start-game");
class StartGameMessage extends base_message_1.BaseMessage {
    constructor() {
        super();
        this.type = "start-game";
    }
}
exports.StartGameMessage = StartGameMessage;
