"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isJoinErrorMessage = base_message_1.createTypeGuard("join-error");
class JoinErrorMessage extends base_message_1.BaseMessage {
    constructor(reason) {
        super();
        this.reason = reason;
        this.type = "join-error";
    }
}
exports.JoinErrorMessage = JoinErrorMessage;
