"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_entity_1 = require("./base-entity");
exports.isPlayer = (possiblePlayer) => {
    return "type" in possiblePlayer && possiblePlayer.type === "player";
};
class Player extends base_entity_1.BaseEntity {
    constructor(order, name) {
        super();
        this.order = order;
        this.name = name;
        this.type = "player";
    }
}
exports.Player = Player;
