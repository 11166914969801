"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isSetupFinishedMessage = base_message_1.createTypeGuard("setup-finished");
class SetupFinishedMessage extends base_message_1.BaseMessage {
    constructor() {
        super();
        this.type = "setup-finished";
    }
}
exports.SetupFinishedMessage = SetupFinishedMessage;
