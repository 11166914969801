"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isVoteFirstTurnMessage = base_message_1.createTypeGuard("vote-first-turn");
class VoteFirstTurnMessage extends base_message_1.BaseMessage {
    constructor(order) {
        super();
        this.order = order;
        this.type = "vote-first-turn";
    }
}
exports.VoteFirstTurnMessage = VoteFirstTurnMessage;
