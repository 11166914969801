"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isTurnInvalidMessage = base_message_1.createTypeGuard("turn-invalid");
class TurnInvalidMessage extends base_message_1.BaseMessage {
    constructor(reason) {
        super();
        this.reason = reason;
        this.type = "turn-invalid";
    }
}
exports.TurnInvalidMessage = TurnInvalidMessage;
