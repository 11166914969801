"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isNewCardMessage = base_message_1.createTypeGuard("new-card");
class NewCardMessage extends base_message_1.BaseMessage {
    constructor(cards) {
        super();
        this.cards = cards;
        this.type = "new-card";
    }
}
exports.NewCardMessage = NewCardMessage;
