"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isPlayerSwitchMessage = base_message_1.createTypeGuard("player-switch");
class PlayerSwitchMessage extends base_message_1.BaseMessage {
    constructor(currentPlayer) {
        super();
        this.currentPlayer = currentPlayer;
        this.type = "player-switch";
    }
}
exports.PlayerSwitchMessage = PlayerSwitchMessage;
