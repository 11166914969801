"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isUpdateCardStacksMessage = base_message_1.createTypeGuard("update-card-stacks");
class UpdateCardStackMessage extends base_message_1.BaseMessage {
    constructor(cardStacks, remainingCardsInDeck) {
        super();
        this.cardStacks = cardStacks;
        this.remainingCardsInDeck = remainingCardsInDeck;
        this.type = "update-card-stacks";
    }
}
exports.UpdateCardStackMessage = UpdateCardStackMessage;
