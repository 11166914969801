"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_message_1 = require("./base-message");
exports.isDropCardMessage = base_message_1.createTypeGuard("drop-card");
class DropCardMessage extends base_message_1.BaseMessage {
    constructor(card, stackId) {
        super();
        this.card = card;
        this.stackId = stackId;
        this.type = "drop-card";
    }
}
exports.DropCardMessage = DropCardMessage;
